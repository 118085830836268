export default function form(
  state = {
    formContentRef: false,
    focusInput: false,
    open: false,
  },
  action
) {
  switch (action.type) {
    case "SET_FOCUS_INPUT":
      return Object.assign({}, state, {
        focusInput: action.payload,
      })

    case "SET_FORM_CONTENT":
      return Object.assign({}, state, {
        formContentRef: action.payload,
      })

    case "OPEN_FORM":
      // Focus the first input !
      state.focusInput.focus()

      // Always shake the form, no matter open state.
      state.formContentRef.classList.add('shake');
      setTimeout(() => {
        state.formContentRef.classList.remove('shake');
      },1600);

      return Object.assign({}, state, {
        open: true,
      })

    case "CLOSE_FORM":
      // BLUR the first input !
      state.focusInput.blur()
      return Object.assign({}, state, {
        open: false,
      })

    default:
      return state
  }
}
