import { createStore as reduxCreateStore, combineReducers } from "redux"

import form from "./reducers/formReducer"

const reducers = combineReducers({
  form,
})

const createStore = () => reduxCreateStore(reducers)
export default createStore
