import React from "react"
import { ThemeProvider } from "styled-components"
import theme from "./src/styles/theme"
import wrapWithProvider from "./wrap-with-provider"

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

export const wrapPageElement = ({ element, props }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>
}

export const wrapRootElement = wrapWithProvider
