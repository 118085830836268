const responsive = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  form: "1280px",
  laptopL: "1440px",
  laptopXL: "1600px",
  desktop: "1920px",
}

const containerWidths = {
  tablet: "700px",
  laptop: "924px",
  laptopL: "1040px",
  desktop: "1420px",
}

const theme = {
  layout: {
    columnWidth: "1028px"
  },
  header: {
    height: "90px",
  },
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    red: "#E30613",
    oldRed: "#EE1D23",
    grey500: "#707070",
    brown100: "#F7F4F2",
    brown1002: "#EBE5E0",
    brown150: "#ebe1da",
    brown200: "#B4A298",
    brown300: "#CEBBAE",
    brown400: "#997E6F",
    brown600: "#6C594E",
    brown: "#A0877A",
    oldbrown: "#927B6E",
    brownDark: "#6D5A4F",
    grey: "#3C3C3B",
  },
  fonts: {
    family: "Montserrat",
    weight: {
      light: 300,
      regular: 500,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    lineHeight: {
      regular: "1.47",
      small: "1.07"
    },
    size: {
      xs: "0.75rem", // 12px
      ss: "0.875rem", // 14px
      s: "1rem", // 16px
      m: "1.125rem", // 18px
      ml: "1.25rem", // 20px
      l: "1.375rem", // 22px
      // h1: "3.5rem", // 56px
      h1: "3rem", // 48px
      h2: "2.31rem", // 37px;
      h3: "1.875rem", // 30px;
      h4: "1.625rem" // 26px
    }
  },
  responsive: responsive,
  containerWidths: containerWidths,
  devices: {
    mobileS: `(min-width: ${responsive.mobileS})`,
    mobileM: `(min-width: ${responsive.mobileM})`,
    mobileL: `(min-width: ${responsive.mobileL})`,
    tablet: `(min-width: ${responsive.tablet})`,
    form: `(min-width: ${responsive.form})`,
    laptop: `(min-width: ${responsive.laptop})`,
    laptopL: `(min-width: ${responsive.laptopL})`,
    laptopXL: `(min-width: ${responsive.laptopXL})`,
    desktop: `(min-width: ${responsive.desktop})`,
    desktopL: `(min-width: ${responsive.desktopL})`,
  },
}

export default theme;
